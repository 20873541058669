<template>
  <Outer>
    <div class="b-auth">
      <h2 class="b-title _center">
        <template v-if="username">{{
            $t('auth---Do not worry, {username}!', { username: username })
          }}</template>
        <template v-else>{{ $t('auth---Do not worry!') }}</template>
      </h2>

      <p class="b-text _note _center">
        {{
          $t(
              'auth---We just sent you a link for new password setup to your email',
          )
        }}
        <strong>{{ maskedEmail }}</strong
        >.
      </p>

      <div class="b-form _code">
        <div v-if="submitError" class="b-row _error">
          <code>{{ submitError }}</code>
        </div>

        <div class="b-row _centered _space_above">
          <button class="b-link" @click="$router.push({ name: 'auth-log-in' })">
            {{ $t('auth---I remembered my password') }}
          </button>
        </div>
      </div>
    </div>
  </Outer>
</template>

<i18n src="../i18n/translations/reset.json"></i18n>

<script>
import Outer from '../components/Outer.vue';
import {mapActions, mapState} from 'vuex';

export default {
  components: {
    Outer,
  },
  data() {
    return {
    };
  },
  mounted() {
    document.title = this.$i18n.t('auth---Password Reset');
  },
  computed: {
    ...mapState({
      username: (state) => state.username,
      maskedEmail: (state) => state.maskedEmail,
      submitError: (state) => state.submitError,
    }),
  },
};
</script>
